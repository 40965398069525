export interface NavigationBarOptions {
	hideNavigationBar?: boolean;
	hideSearch?: boolean;
	hideMainMenu?: boolean;
	hideClinicSelection?: boolean;
}

export const HideNavigationBar: NavigationBarOptions = {
	hideNavigationBar: true
};
